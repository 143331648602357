@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Play&display=swap');

body {
  margin: 0;
  background: black;
  color: white;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  color: white;
}

input[type='number'] {
  width: 60px;
  padding: 8px 12px;
  border: solid #404040;
  background: #202020;
}

button {
  width: 200px;
  background: darkgoldenrod;
}
